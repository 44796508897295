.app-tile {
  display: block;
  width: 200px;
  padding: 1rem;
  margin: 1rem;
  background-color: #3498db;
  color: #ecf0f1;
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.app-tile:hover {
  background-color: #2980b9;
}

.app-tile-content h2 {
  margin: 0;
}

.app-tile-content p {
  margin: 0.5rem 0 0;
}
